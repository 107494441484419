<!-- 交通住宿 -->
<template>
  <div class="transportationAndAccommodation">
    <el-row>
      <el-col :span="24" :lg="24">
        <div class="pageBox">
          <div class="header pc">
            <el-image
              style="width: 100%"
              :src="require('../../assets/images/registerMeeting/banner.png')"
              :fit="'fill'"
            >
            </el-image>
            <div class="title-box">
              <div class="title" :class="{ weibei: lang }">
                {{ $t("participation") }}
              </div>
              <div class="subtitle">
                <div
                  class="register"
                  :class="{ weibei: lang }"
                  @click="$router.push({ path: '/registerMeeting' })"
                >
                  {{ $t("regist") }}
                </div>
                <div
                  class="traffic"
                  :class="{ weibei: lang }"
                  @click="
                    $router.push({ path: '/transportationAndAccommodation' })
                  "
                >
                  {{ $t("logistics") }}
                </div>
              </div>
            </div>
          </div>
          <div class="header h5">
            <img
              src="@/assets/images/registerMeeting/banner1.png"
              alt=""
              style="width: 100%; height: 100%; object-fit: cover"
            />
            <div class="title-box">
              <div class="title" :class="{ weibei: lang }">
                {{ $t("participation") }}
              </div>
              <div class="subtitle">
                <div
                  class="register"
                  :class="{ weibei: lang }"
                  @click="$router.push({ path: '/registerMeeting' })"
                >
                  {{ $t("regist") }}
                </div>
                <div
                  class="traffic"
                  :class="{ weibei: lang }"
                  @click="
                    $router.push({ path: '/transportationAndAccommodation' })
                  "
                >
                  {{ $t("logistics") }}
                </div>
              </div>
            </div>
          </div>

          <!-- <p :class="{ KaiTi: lang }" style="margin-top: 60px;font-size:42px;font-weight: 700; color:#405e3c;margin-bottom: 60px;">
                        {{$t('provideTimely')}}
                    </p> -->

          <div class="lcontainer container">
            <div class="title" :class="{ weibei: lang }">
              {{ $t("beijingCenterHotels") }}
            </div>
            <div class="table-box">
              <el-image style="width: 100%" :src="tableImg" :fit="'fill'">
              </el-image>
            </div>
            <div class="subtitle-box">
              <div class="subtitle" :class="{ weibei: lang }">
                {{ $t("mainAccommodation") }}
              </div>
              <div class="info-block pc">
                <span class="g-color">{{ $t("address") }}</span
                >{{ lang ? "：" : ":" }} {{ $t("mainAccommodationAddress") }}
                <span class="g-color m-l-30">{{ $t("pr") }}</span
                >{{ lang ? "：" : ":" }} {{ $t("mainAccommodationpr") }}
                <span class="g-color m-l-30">{{ $t("tel") }}</span
                >{{ lang ? "：" : ":" }} {{ $t("mainAccommodationTel") }}
                <span class="g-color m-l-30">{{ $t("website") }}</span
                >{{ lang ? "：" : ":" }}
                <a
                  class="a-color"
                  target="_blank"
                  :href="$t('mainAccommodationWebsite')"
                  >{{ $t("mainAccommodationWebsite") }}</a
                >
                <span class="g-color m-l-30">{{ $t("distancetoBICC") }}</span
                >{{ lang ? "：" : ":" }}
                {{ $t("mainAccommodationDistancetoBICC") }}
              </div>
              <div class="info-block h5">
                <p class="m-b-14">
                  <span class="g-color">{{ $t("address") }}</span
                  >{{ lang ? "：" : ":" }} {{ $t("mainAccommodationAddress") }}
                </p>
                <p class="m-b-14">
                  <span class="g-color">{{ $t("pr") }}</span
                  >{{ lang ? "：" : ":" }} {{ $t("mainAccommodationpr") }}
                </p>
                <p class="m-b-14">
                  <span class="g-color">{{ $t("tel") }}</span
                  >{{ lang ? "：" : ":" }} {{ $t("mainAccommodationTel") }}
                </p>
                <p class="m-b-14">
                  <span class="g-color">{{ $t("website") }}</span
                  >{{ lang ? "：" : ":" }}
                  <a
                    class="a-color"
                    target="_blank"
                    :href="$t('mainAccommodationWebsite')"
                    >{{ $t("mainAccommodationWebsite") }}</a
                  >
                </p>
                <p>
                  <span class="g-color">{{ $t("distancetoBICC") }}</span
                  >{{ lang ? "：" : ":" }}
                  {{ $t("mainAccommodationDistancetoBICC") }}
                </p>
              </div>
              <div class="img-block m-t-24">
                <el-image
                  style="width: 100%"
                  :src="
                    require('../../assets/images/transportationAndAccommodation/c1.png')
                  "
                  :fit="'fill'"
                >
                </el-image>
                <div class="city-info hidden-sm-and-down">
                  {{ $t("mainAccommodationInfos") }}
                </div>
              </div>
            </div>
            <div class="subtitle-box" v-if="0">
              <div class="subtitle" :class="{ weibei: lang }">
                {{ $t("huiyuanHotel") }}
              </div>
              <div class="info-block pc">
                <span class="g-color">{{ $t("address") }}</span
                >{{ lang ? "：" : ":" }} {{ $t("huiyuanHotelAddress") }}
                <span class="g-color m-l-30">{{ $t("pr") }}</span
                >{{ lang ? "：" : ":" }} {{ $t("huiyuanHotelPr") }}
                <span class="g-color m-l-30">{{ $t("tel") }}</span
                >{{ lang ? "：" : ":" }} {{ $t("huiyuanHotelTel") }}
                <span class="g-color m-l-30">{{ $t("website") }}</span
                >{{ lang ? "：" : ":" }}
                <a
                  target="_blank"
                  :href="$t('huiyuanHotelWebsite')"
                  class="a-color"
                  >{{ $t("huiyuanHotelWebsite") }}</a
                >
                {{ lang ? "" : "(Chinese version only)" }}
                <span class="g-color m-l-30">{{ $t("distancetoBICC") }}</span
                >{{ lang ? "：" : ":" }}
                {{ $t("huiyuanHotelToBICC") }}
              </div>

              <div class="info-block h5">
                <p class="m-b-14">
                  <span class="g-color">{{ $t("address") }}</span
                  >{{ lang ? "：" : ":" }} {{ $t("huiyuanHotelAddress") }}
                </p>
                <p class="m-b-14">
                  <span class="g-color">{{ $t("pr") }}</span
                  >{{ lang ? "：" : ":" }} {{ $t("huiyuanHotelPr") }}
                </p>
                <p class="m-b-14">
                  <span class="g-color">{{ $t("tel") }}</span
                  >{{ lang ? "：" : ":" }} {{ $t("huiyuanHotelTel") }}
                </p>
                <p class="m-b-14">
                  <span class="g-color">{{ $t("website") }}</span
                  >{{ lang ? "：" : ":" }}
                  <a
                    target="_blank"
                    :href="$t('huiyuanHotelWebsite')"
                    class="a-color"
                    >{{ $t("huiyuanHotelWebsite") }}</a
                  >
                  {{ lang ? "" : "(Chinese version only)" }}
                </p>
                <p>
                  <span class="g-color">{{ $t("distancetoBICC") }}</span
                  >{{ lang ? "：" : ":" }} {{ $t("huiyuanHotelToBICC") }}
                </p>
              </div>
              <div class="img-block m-t-24">
                <el-image
                  style="width: 100%"
                  :src="
                    require('../../assets/images/transportationAndAccommodation/c3.png')
                  "
                  :fit="'fill'"
                >
                </el-image>
                <div class="city-info hidden-sm-and-down">
                  {{ $t("huiyuanHotelInfos") }}
                </div>
              </div>
            </div>
            <div class="subtitle-box">
              <div class="subtitle" :class="{ weibei: lang }">
                {{ $t("yayuncunHotel") }}
              </div>
              <div class="info-block pc">
                <span class="g-color">{{ $t("address") }}</span
                >{{ lang ? "：" : ":" }} {{ $t("yayuncunHotelAddress") }}
                <span class="g-color m-l-30">{{ $t("pr") }}</span
                >{{ lang ? "：" : ":" }} {{ $t("huiyuanHotelPr") }}
                <span class="g-color m-l-30">{{ $t("tel") }}</span
                >{{ lang ? "：" : ":" }} {{ $t("yayuncunHotelTel") }}
                <span class="g-color m-l-30">{{ $t("website") }}</span
                >{{ lang ? "：" : ":" }}
                <a
                  class="a-color"
                  target="_blank"
                  :href="$t('yayuncunHotelWebsite')"
                  v-html="$t('yayuncunHotelWebsite')"
                ></a>
                {{ lang ? "" : "(Chinese version only)" }}
                <span class="g-color m-l-30">{{ $t("distancetoBICC") }}</span
                >{{ lang ? "：" : ":" }} {{ $t("yayuncunHotelToBICC") }}
              </div>
              <div class="info-block h5">
                <p class="m-b-14">
                  <span class="g-color">{{ $t("address") }}</span
                  >{{ lang ? "：" : ":" }} {{ $t("yayuncunHotelAddress") }}
                </p>
                <p class="m-b-14">
                  <span class="g-color">{{ $t("pr") }}</span
                  >{{ lang ? "：" : ":" }} {{ $t("huiyuanHotelPr") }}
                </p>
                <p class="m-b-14">
                  <span class="g-color">{{ $t("tel") }}</span
                  >{{ lang ? "：" : ":" }} {{ $t("yayuncunHotelTel") }}
                </p>
                <p class="m-b-14">
                  <span class="g-color">{{ $t("website") }}</span
                  >{{ lang ? "：" : ":" }}
                  <a
                    class="a-color"
                    target="_blank"
                    :href="$t('yayuncunHotelWebsite')"
                    v-html="$t('yayuncunHotelWebsite')"
                  ></a>
                  {{ lang ? "" : "(Chinese version only)" }}
                </p>
                <p>
                  <span class="g-color">{{ $t("distancetoBICC") }}</span
                  >{{ lang ? "：" : ":" }} {{ $t("yayuncunHotelToBICC") }}
                </p>
              </div>
              <div class="img-block m-t-24">
                <el-image
                  style="width: 100%"
                  :src="
                    require('../../assets/images/transportationAndAccommodation/c4.png')
                  "
                  :fit="'fill'"
                >
                </el-image>
                <div class="city-info hidden-sm-and-down">
                  {{ $t("yayuncunHotelInfos") }}
                </div>
              </div>
            </div>
            <div class="subtitle-box">
              <div class="subtitle" :class="{ weibei: lang }">
                {{ $t("wuzhouHotel") }}
              </div>
              <div class="info-block pc">
                <span class="g-color">{{ $t("address") }}</span
                >{{ lang ? "：" : ":" }} {{ $t("wuzhouHotelAddress") }}
                <span class="g-color m-l-30">{{ $t("pr") }}</span
                >{{ lang ? "：" : ":" }} {{ $t("wuzhouHotelPr") }}
                <span class="g-color m-l-30">{{ $t("tel") }}</span
                >{{ lang ? "：" : ":" }} {{ $t("wuzhouHotelTel") }}
                <span class="g-color m-l-30">{{ $t("distancetoBICC") }}</span
                >{{ lang ? "：" : ":" }}
                {{ $t("wuzhouHotelToBICC") }}
                <span class="g-color m-l-30">{{ $t("lxr") }}</span
                >{{ lang ? "：" : ":" }}
                {{ $t("xufang") }}
                <span class="g-color m-l-30">{{ $t("dh") }}</span
                >{{ lang ? "：" : ":" }}
                {{ $t("xufd") }}
                <span class="g-color m-l-30">{{ $t("fj") }}</span
                >{{ lang ? "：" : ":" }}
                <span v-html="$t('dinganglianjie')"></span>
                <!-- <span class="g-color m-l-30"> {{ $t('distancetoCNCC') }}</span>{{lang?'：':':'}} 
                                {{ $t('wuzhouHotelToCNCC') }} -->
              </div>
              <div class="info-block h5">
                <p class="m-b-14">
                  <span class="g-color">{{ $t("address") }}</span
                  >{{ lang ? "：" : ":" }} {{ $t("wuzhouHotelAddress") }}
                </p>
                <p class="m-b-14">
                  <span class="g-color">{{ $t("pr") }}</span
                  >{{ lang ? "：" : ":" }} {{ $t("wuzhouHotelPr") }}
                </p>
                <p class="m-b-14">
                  <span class="g-color">{{ $t("tel") }}</span
                  >{{ lang ? "：" : ":" }} {{ $t("wuzhouHotelTel") }}
                </p>
                <!-- <p class="m-b-14"> <span class="g-color">{{ $t('website') }}</span>{{lang?'：':':'}}  <span
                                        class="a-color">www.bcghotel.com</span></p> -->
                <!-- <p class="m-b-14"> <span class="g-color"> {{ $t('distancetoCNCC') }}</span>{{lang?'：':':'}} 
                                    {{ $t('wuzhouHotelToCNCC') }}</p> -->
                <p>
                  <span class="g-color">{{ $t("distancetoBICC") }}</span
                  >{{ lang ? "：" : ":" }} {{ $t("wuzhouHotelToBICC") }}
                </p>
              </div>

              <div class="img-block m-t-24">
                <el-image
                  style="width: 100%"
                  :src="
                    require('../../assets/images/transportationAndAccommodation/c2.png')
                  "
                  :fit="'fill'"
                >
                </el-image>
                <div class="city-info hidden-sm-and-down">
                  {{ $t("wuzhouHotelInfos") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tableImg: null,
    };
  },
  created() {
    this.innit();
  },
  computed: {
    lang() {
      const localeLang = window.localStorage.getItem("locale") || "zh-CN";
      return localeLang === "zh-CN";
    },
  },
  methods: {
    innit() {
      if (window.localStorage.getItem("locale") == "zh-CN") {
        this.tableImg = require("../../assets/images/transportationAndAccommodation/table.png");
      } else {
        this.tableImg = require("../../assets/images/transportationAndAccommodation/table-en.png");
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
@media screen and (min-width: 728px) {
  .transportationAndAccommodation {
    width: 100%;
    overflow: hidden;

    .pageBox {
      .h5 {
        display: none;
      }

      .header {
        position: relative;
        overflow: hidden;

        .title-box {
          position: absolute;
          top: 50%;
          left: 50%;
          color: #fff;
          transform: translate(-50%, -50%);

          .title {
            padding: 0 20px;
            height: 80px;
            line-height: 80px;
            text-align: center;
            background: rgba(141, 198, 63, 0.9);
            font-weight: 700;
            font-size: 42px;
            margin: 0 auto 30px auto;
            width: fit-content;
          }

          .subtitle {
            display: flex;
            font-size: 20px;
            justify-content: center;
          }

          .register {
            width: 130px;
            height: 54px;
            line-height: 54px;
            top: 379px;
            background: #8DC63F;
            margin-right: 24px;
            cursor: pointer;
          }

          .traffic {
            width: 130px;
            height: 54px;
            line-height: 54px;
            top: 379px;
            background: #405E3C;
            cursor: pointer;
          }
        }
      }

      .container {
        margin-top: 13px;
        margin-bottom: 36px;
        padding: 56px 44px;

        .title {
          font-style: normal;
          font-weight: 700;
          font-size: 28px;
          line-height: 39px;
          color: #405E3C;
        }

        .subtitle-box {
          text-align: left;

          .subtitle {
            font-size: 28px;
            color: #405E3C;
            margin: 76px 0 25px 0;
            font-weight: 700;
          }
        }

        .table-box {
          margin-top: 46px;
        }

        .img-block {
          position: relative;

          .city-info {
            width: 429px;
            background: rgba(141, 198, 63, 0.9);
            position: absolute;
            top: 10px;
            color: #fff;
            padding: 56px;
            box-sizing: border-box;
            line-height: 26px;
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 728px) {
  p {
    font-size: 14px;
  }

  .transportationAndAccommodation {
    width: 100%;
    overflow: hidden;

    .pageBox {
      .pc {
        display: none;
      }

      .header {
        position: relative;
        overflow: hidden;
        height: 190px;
        width: 100%;

        .title-box {
          position: absolute;
          top: 50%;
          left: 50%;
          color: #fff;
          transform: translate(-50%, -50%);

          .title {
            width: 164px;
            height: 60px;
            line-height: 60px;
            text-align: center;
            background: rgba(141, 198, 63, 0.9);
            font-weight: 700;
            font-size: 22px;
            margin: 0 0 30px 45px;
          }

          .subtitle {
            display: flex;
            font-size: 20px;
          }

          .register {
            width: 100px;
            height: 44px;
            line-height: 44px;
            top: 379px;
            background: #8DC63F;
            margin-right: 24px;
          }

          .traffic {
            width: 100px;
            height: 44px;
            line-height: 44px;
            top: 379px;
            background: #405E3C;
          }
        }
      }

      .container {
        padding: 40px 20px;
      }

      .title {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 39px;
        color: #405E3C;
      }

      .subtitle-box {
        text-align: left;

        .subtitle {
          font-size: 20px;
          color: #405E3C;
          margin: 76px 0 25px 0;
          font-weight: 700;
        }
      }

      .table-box {
        margin-top: 46px;
      }

      .img-block {
        position: relative;

        .city-info {
          width: 429px;
          height: 293px;
          background: rgba(141, 198, 63, 0.9);
          position: absolute;
          bottom: 92px;
          color: #fff;
          padding: 56px;
          box-sizing: border-box;
          line-height: 26px;
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
</style>